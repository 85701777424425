/**
 * External dependencies
 */
import createClient from 'openapi-fetch';
import { paths } from '@ackwest/rc-api-js-sdk';

/**
 * Internal dependencies
 */
import { ClientType } from './types';

export interface GetClient {
	(token: string): ClientType;
	cache?: Map<string, ClientType>;
}

const getClient: GetClient = (token) =>
	(getClient.cache = getClient.cache ?? new Map()) &&
	(getClient.cache.get(token) ??
		getClient.cache
			.set(
				token,
				createClient<paths>({
					baseUrl: process.env.REACT_APP_API_URL,
					headers: { Authorization: `Bearer ${token}` },
				})
			)
			.get(token)!);

export default getClient;
