/**
 * External dependencies
 */
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import classes from './HeaderSearch.module.scss';

export type HeaderSearchProps = {
	onChange?: (search: string) => void;
	icon?: JSX.Element | boolean;
	placeholder?: string;
};

/**
 * Header Search component.
 */
const HeaderSearch: FC<HeaderSearchProps> = ({
	onChange,
	icon = true,
	placeholder = 'Search',
}) => (
	<Input
		prefix={icon === true ? <SearchOutlined /> : icon}
		placeholder={placeholder}
		className={classes.input}
		onChange={(event) => onChange?.(event.target.value)}
	/>
);

export default HeaderSearch;
