/**
 * External dependencies
 */
import { Tag } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import { getCssVar } from '@/utils';
import { getStatusData, OfferForDisplay } from '@/offers';
import { ThunderboltOutlined } from '@ant-design/icons';

export type StatusColumnProps = Pick<OfferForDisplay, 'status' | 'isBoosted'>;

/**
 * Offers Table component.
 */
const StatusColumn: FC<StatusColumnProps> = ({ isBoosted, status }) => {
	const { backgroundColor, textColor, icon, name } = getStatusData(status);

	return (
		<>
			<Tag color={backgroundColor} style={{ color: textColor }}>
				{icon}
				{name}
			</Tag>
			{isBoosted && (
				<Tag
					color={getCssVar('userColor010')}
					style={{ color: getCssVar('userColor0') }}
				>
					<ThunderboltOutlined />
				</Tag>
			)}
		</>
	);
};

export default StatusColumn;
