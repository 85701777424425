/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import filters from './filters';
import pagination from './pagination';
import selection from './selection';
import sorting from './sorting';

const offersList = combineReducers({
	filters,
	pagination,
	selection,
	sorting,
});

export default offersList;
