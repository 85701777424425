/**
 * Internal dependencies
 */
import { isAction } from '../utils';
import { Reducer } from '@/store/types';
import { setAuthToken } from '../actions';
import initialState from '@/store/initialState';

export type AuthState = {
	token: string | null;
	initial: boolean;
};

const auth: Reducer<AuthState> = (state = initialState.auth, action) =>
	isAction(action, setAuthToken)
		? {
				...state,
				token: action.payload,
				initial: false,
			}
		: state;

export default auth;
