/**
 * External dependencies
 */
import { Divider, Flex, Input, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { DefaultOptionType } from 'antd/es/select';
import { title } from 'radash';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import { FileInput } from '@/components';
import OfferFormItem from '@/offer-form/components/OfferFormItem';

export type OfferDetailsMarketingCopyProps = {
	categories: DefaultOptionType[];
	industries: DefaultOptionType[];
	onCopy: (name: NamePath) => void;
	onUpload: (data: unknown) => void;
	subcategories: DefaultOptionType[];
	topics: DefaultOptionType[];
	uploadUrl: string;
};

const { TextArea } = Input;

const OfferDetailsMarketingCopy: FC<OfferDetailsMarketingCopyProps> = ({
	categories,
	industries,
	onCopy,
	onUpload,
	subcategories,
	topics,
	uploadUrl,
}) => (
	<>
		<OfferFormItem label="Headline" name="headline" onCopy={onCopy}>
			<Input />
		</OfferFormItem>
		<OfferFormItem
			label="Marketing Text"
			name="marketingText"
			onCopy={onCopy}
		>
			<TextArea rows={3} />
		</OfferFormItem>
		<OfferFormItem
			label="Buyer Message"
			name="buyerMessage"
			onCopy={onCopy}
		>
			<TextArea rows={3} />
		</OfferFormItem>
		<Flex gap="middle" align="stretch">
			{['smallImage', 'largeImage'].map((name) => (
				<FileInput
					label={title(name)}
					name={name}
					uploadUrl={uploadUrl}
					withPreview
					withDragDrop
					onUpload={onUpload}
				/>
			))}
		</Flex>
		<Divider />
		<OfferFormItem label="Industry" name="industry" onCopy={onCopy}>
			<Select options={industries} />
		</OfferFormItem>
		<OfferFormItem label="Category" name="category" onCopy={onCopy}>
			<Select options={categories} />
		</OfferFormItem>
		<OfferFormItem label="Subcategory" name="subcategory" onCopy={onCopy}>
			<Select options={subcategories} />
		</OfferFormItem>
		<OfferFormItem label="Topics" name="topics" onCopy={onCopy}>
			<Select options={topics} mode="multiple" />
		</OfferFormItem>
	</>
);

export default OfferDetailsMarketingCopy;
