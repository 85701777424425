/**
 * External dependencies
 */
import { ComponentType } from 'react';
import { createBrowserRouter } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { Main } from '@/components';

export const prepareRoutes = (routes: Record<string, ComponentType>) => [
	{
		path: '/',
		element: <Main />,
		children: Object.entries(routes).map(([path, Component]) => ({
			path,
			element: <Component />,
		})),
	},
];

export const createRouter = (routes: Record<string, ComponentType>) =>
	createBrowserRouter(prepareRoutes(routes));
