/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { Offer, OffersQueryProps } from '@/offers/types';
import { usePatchOfferMutation } from '@/api';
import { getOffersQueryArgs } from '@/offers/utils';

type UseOfferActionsContainer = ContainerFn<
	{
		update: (offer: Partial<Offer> & Pick<Offer, 'id'>) => Promise<void>;
	},
	{ offersQueryProps?: OffersQueryProps } | void
>;

const useUpdateOfferContainer: UseOfferActionsContainer = (args) => {
	const [update] = usePatchOfferMutation({
		fixedCacheKey: 'updateOffer',
	});

	const offersQueryProps = args && args.offersQueryProps;

	return {
		update: useCallback(
			async (offer) => {
				const result = await update({
					params: {
						path: {
							offer_id: offer.id,
						},
					},
					/**
					 * Generated API types are wrong - all fields are optional
					 * in PATH /offers/{offer_id}.
					 *
					 * @see https://chilly-mouse-18.redoc.ly/tag/offers#operation/update-offer
					 */
					body: offer as Offer,
					offersQueryArgs: offersQueryProps
						? getOffersQueryArgs(offersQueryProps)
						: undefined,
				});

				if ('error' in result) {
					throw result.error;
				}
			},
			[update, offersQueryProps]
		),
	};
};

export default useUpdateOfferContainer;
