/**
 * External dependencies
 */
import { App } from 'antd';
import { ReactNode, useCallback, useRef, useState } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { NewBoostModal } from '@/offers/components';
import { OfferBoostData } from '@/offers/types';
import { usePostOffersBoostsMutation } from '@/api';

type UseNewBoostContainer = ContainerFn<
	{
		modal: ReactNode;
		boost: (ids: string | string[]) => void;
	},
	{
		onDone?: () => void;
	}
>;

const useNewBoostContainer: UseNewBoostContainer = ({ onDone }) => {
	const [createBoost] = usePostOffersBoostsMutation();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { message } = App.useApp();

	const idsRef = useRef<string[]>([]);

	const handleNewBoost = async (data: OfferBoostData) => {
		setIsLoading(true);

		const results = await Promise.all(
			idsRef.current.map((id) =>
				createBoost({
					params: {
						path: {
							offer_id: id,
						},
					},
					body: {
						boost_factor: data.boostFactor,
						expiration: data.expirationDate.toISOString(),
						offer_id: id,
						start: data.startDate.toISOString(),
					},
				})
			)
		);

		const successCount = results.filter(
			(result) => !('error' in result)
		).length;

		const errorCount = results.filter((result) => 'error' in result).length;

		if (successCount) {
			message.success(
				`${successCount} offer${successCount > 1 ? 's' : ''} boosted`
			);
		}

		if (errorCount) {
			message.error(
				`${errorCount} offer${errorCount > 1 ? 's' : ''} could not be boosted`
			);
		}

		setIsModalOpen(false);
		setIsLoading(false);
		onDone?.();
	};

	return {
		modal: (
			<NewBoostModal
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onApply={handleNewBoost}
				loading={isLoading}
			/>
		),
		boost: useCallback((ids) => {
			idsRef.current = Array.isArray(ids) ? ids : [ids];
			setIsModalOpen(true);
		}, []),
	};
};

export default useNewBoostContainer;
