/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { AuthHandler } from '@/auth/components';
import { ReactComponent as Logo } from '@/images/logo.svg';
import { UserContainer } from '@/user';
import classes from './Main.module.scss';
import Header from '../Header';
import UserMenu from '../UserMenu';

type MainProps = PropsWithChildren;

const Main: FC<MainProps> = () => (
	<AuthHandler>
		<Layout className={classes.layout}>
			<Header>
				<Logo />
				<UserContainer>
					{({ displayName, logOut }) =>
						displayName && (
							<UserMenu
								items={[
									{
										label: 'Log out',
										key: 'log-out',
										onClick: logOut,
									},
								]}
								userName={displayName}
							/>
						)
					}
				</UserContainer>
			</Header>
			<Layout.Content className={classes.content}>
				<Outlet />
			</Layout.Content>
		</Layout>
	</AuthHandler>
);

export default Main;
