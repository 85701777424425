/**
 * External dependencies
 */
import { useSelector } from 'react-redux';

/**
 * Internal dependencies
 */
import { selectUserData } from '@/store/selectors';

const useUserData = () => useSelector(selectUserData);

export default useUserData;
