/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { OfferFormHeader } from '@/offer-form/components';
import {
	useOfferFormContainer,
	useOfferScheduleContainer,
	useOfferStatusContainer,
} from '@/offer-form/containers';
import { Form } from 'antd';
import {
	useBuyersFilterOptions,
	useSourceFilterOptions,
} from '@/pages/offers-management/hooks';
import { OfferFormValues } from '@/offer-form';

type NewOfferPageProps = {};

const NewOfferPage: FC<NewOfferPageProps> = () => {
	const [schedule, setSchedule] = useOfferScheduleContainer();
	const [status, setStatus] = useOfferStatusContainer();
	const [fields, onChange] = useOfferFormContainer();

	return (
		<Form<OfferFormValues>
			layout="vertical"
			fields={fields}
			onValuesChange={onChange}
		>
			<OfferFormHeader
				buyers={useBuyersFilterOptions()}
				onScheduleChange={setSchedule}
				onStatusChange={setStatus}
				schedule={schedule}
				sources={useSourceFilterOptions()}
				status={status}
			/>
		</Form>
	);
};

export default NewOfferPage;
