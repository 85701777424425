/**
 * Internal dependencies
 */
import { OffersManagementPage } from '@/pages';
import { Routes } from './types';
import { NewOfferPage } from '@/pages/new-offer';

export const routes: Routes = {
	'/': OffersManagementPage,
	'/new-offer': NewOfferPage,
};
