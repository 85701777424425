/**
 * External dependencies.
 */
import {
	CloseCircleOutlined,
	PauseCircleOutlined,
	PlusOutlined,
	ThunderboltOutlined,
} from '@ant-design/icons';
import { OffersTableHeaderActionConfig } from './types';

/**
 * Internal dependencies.
 */

export const getHeaderActions = (
	hasSelection: boolean
): OffersTableHeaderActionConfig[] =>
	[
		{
			disabled: !hasSelection,
			icon: <ThunderboltOutlined />,
			label: 'Boost',
			type: 'boost',
		},
		{
			disabled: !hasSelection,
			icon: <CloseCircleOutlined />,
			label: 'End',
			type: 'end',
		},
		{
			disabled: !hasSelection,
			icon: <PauseCircleOutlined />,
			label: 'Pause',
			type: 'pause',
		},
		{
			type: 'create',
			icon: <PlusOutlined />,
			label: 'Add new',
			isPrimary: true,
		},
	] as const;
