/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { OfferFilters } from '@/offers';
import { OffersFilterConfig } from '../../types';
import { processFiltersSelection } from '../../utils';
import { useBuyersFilterOptions, useSourceFilterOptions } from '../../hooks';
import { useOffersListFilters } from '@/state';
import { offerSubtypeOptions } from '@/offers/config';

type UseFiltersContainer = ContainerFn<{
	filters: OffersFilterConfig<keyof OfferFilters>[];
	setFilters: (values: Partial<OfferFilters>) => void;
	values: OfferFilters;
}>;

const useFiltersContainer: UseFiltersContainer = () => {
	const [values, setFilters] = useOffersListFilters();

	return {
		values,
		setFilters: useCallback(
			(filters: Partial<OfferFilters>) =>
				setFilters(processFiltersSelection(filters, values)),
			[setFilters, values]
		),
		filters: [
			{
				allDisabled: !values.source.length,
				label: 'Source',
				multiple: true,
				name: 'source',
				options: useSourceFilterOptions(),
			},
			{
				allDisabled: !values.subtype.length,
				label: 'Sub-type',
				multiple: true,
				name: 'subtype',
				options: offerSubtypeOptions,
			},
			{
				allDisabled: !values.buyer.length,
				label: 'Buyers',
				multiple: true,
				name: 'buyer',
				options: useBuyersFilterOptions(),
			},
			{
				name: 'startDate',
				label: 'Start date',
				type: 'date',
			},
			{
				name: 'endDate',
				label: 'End date',
				type: 'date',
			},
		],
	};
};

export default useFiltersContainer;
