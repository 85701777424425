/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { setOffersListSelection, useDispatch, useSelector } from '@/store';

const useOffersListSelection = () => {
	const dispatch = useDispatch();

	return [
		useSelector((state) => state.offersList.selection),
		useCallback(
			(selection: string[]) =>
				dispatch(setOffersListSelection(selection)),
			[dispatch]
		),
	] as const;
};

export default useOffersListSelection;
