/**
 * External dependencies
 */
import { FC } from 'react';
import { Form, InputNumber } from 'antd';

/**
 * Internal dependencies
 */
import { DatePicker, FormModal } from '@/components';
import { OfferBoostData } from '@/offers';

export type NewBoostModalProps = {
	loading?: boolean;
	onApply: (formData: OfferBoostData) => void;
	onCancel: () => void;
	open: boolean;
};

const FormItem = Form.Item<OfferBoostData>;

const NewBoostModal: FC<NewBoostModalProps> = ({
	onApply,
	open = false,
	...props
}) => (
	<FormModal<OfferBoostData>
		okText={'Apply'}
		onSubmit={onApply}
		open={open}
		title={'Add new boost'}
		subtitle={'Define boost details'}
		form={{
			layout: 'vertical',
		}}
		{...props}
	>
		<FormItem
			label="Start"
			name="startDate"
			rules={[
				{
					required: true,
					message: 'Please select start date.',
				},
			]}
		>
			<DatePicker showTime style={{ width: '100%' }} />
		</FormItem>
		<FormItem
			label="Expiration"
			name="expirationDate"
			rules={[
				{
					required: true,
					message: 'Please select expiration date.',
				},
			]}
		>
			<DatePicker showTime style={{ width: '100%' }} />
		</FormItem>
		<FormItem
			label="Boost Factor"
			name="boostFactor"
			rules={[
				{
					required: true,
					message: 'Please enter boost factor.',
				},
			]}
		>
			<InputNumber addonAfter="%" />
		</FormItem>
	</FormModal>
);

export default NewBoostModal;
