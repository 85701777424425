const replaceByIndexInternal = <T>(
	arr: T[],
	index: number,
	newValue?: T
): T[] => {
	if (index < 0 || index >= arr.length) {
		return arr;
	}

	const result = [...arr];

	if (newValue) {
		result.splice(index, 1, newValue);
	} else {
		result.splice(index, 1);
	}

	return result;
};

export const replaceByIndex = <T>(arr: T[], index: number, newValue: T): T[] =>
	replaceByIndexInternal(arr, index, newValue);

export const replaceByValue = <T>(arr: T[], value: T, newValue: T): T[] =>
	replaceByIndexInternal(arr, arr.indexOf(value), newValue);

export const removeByIndex = <T>(arr: T[], index: number): T[] =>
	replaceByIndexInternal(arr, index);

export const removeByValue = <T>(arr: T[], value: T): T[] =>
	removeByIndex(arr, arr.indexOf(value));
