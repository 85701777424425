/**
 * External dependencies
 */
import { formatISO } from 'date-fns';
import { saveAs } from 'file-saver';
import { snake } from 'radash';

/**
 * Internal dependencies
 */
import { Offer, OfferForDisplay, OffersQueryProps, OfferStatus } from './types';
import { statusConfig } from './config';
import { offerStatuses } from './data';

export const prepareOfferForDisplay = (offer: Offer): OfferForDisplay => ({
	completion: {
		completed: offer.survey_details?.completes?.overall.total_achieved,
		remaining: offer.survey_details?.completes?.overall.total_remaining,
		total: offer.survey_details?.completes?.overall.total_needed,
	},
	endDate: offer.schedule?.end,
	fixedCPI: offer.fixed_cpi,
	isBoosted: offer.boost?.is_active,
	key: offer.id,
	offerName: offer.offer_name,
	offerSubtype: offer.offer_subtype,
	startDate: offer.schedule?.start,
	status: offer.status,
	variableCPI: offer.variable_cpi,
});

export const downloadOffer = (offer: Offer) =>
	saveAs(
		new Blob([JSON.stringify(offer, null, 2)], {
			type: 'application/json',
		}),
		`${snake(offer.offer_name).replace(/_$/, '')}.json`
	);

export const getOffersQueryArgs = ({
	filters: {
		buyer,
		endDate,
		source,
		startDate,
		status: catalog_status,
		subtype,
	},
	order = 'asc',
	orderBy,
	page = 1,
	perPage = 10,
}: OffersQueryProps) => ({
	params: {
		query: {
			buyer,
			catalog_status,
			ending_after: startDate
				? formatISO(new Date(startDate))
				: undefined,
			ending_before: endDate ? formatISO(new Date(endDate)) : undefined,
			offset: (page - 1) * perPage,
			page_size: perPage,
			sort: orderBy
				? [`${order === 'desc' ? '-' : ''}${orderBy}`]
				: undefined,
			source,
			subtype,
		},
	},
});

/**
 * Gets the offer status data.
 */
export const getStatusData = (status: OfferStatus) => ({
	backgroundColor: statusConfig[status].backgroundColor,
	icon: statusConfig[status].icon,
	name: offerStatuses[status],
	textColor: statusConfig[status].textColor,
});
