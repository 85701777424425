/**
 * External dependencies
 */
import { CalendarOutlined } from '@ant-design/icons';
import { DatePicker, Popover, Tag } from 'antd';
import { FC, useState } from 'react';
import { format } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

/**
 * Internal dependencies
 */
import { Schedule } from '@/types';

const { RangePicker } = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

type OfferScheduleProps = {
	schedule?: Schedule;
	onChange?: (newSchedule: Schedule | undefined) => void;
	dateFormat?: string;
};

const OfferSchedule: FC<OfferScheduleProps> = ({
	schedule,
	onChange,
	dateFormat = 'dd.MM.yyyy, hh:mm a',
}: OfferScheduleProps) => {
	const [open, setOpen] = useState(false);

	return (
		<Popover
			trigger="click"
			open={open}
			onOpenChange={setOpen}
			content={
				<RangePicker
					value={
						schedule
							? [
									schedule.start
										? new Date(schedule.start)
										: undefined,
									schedule.end
										? new Date(schedule.end)
										: undefined,
								]
							: undefined
					}
					showTime={{ format: 'hh:mm a' }}
					format={dateFormat}
					onOk={(dates) => {
						setOpen(false);

						onChange?.({
							start: dates?.[0]?.getTime(),
							end: dates?.[1]?.getTime(),
						});
					}}
				/>
			}
		>
			<Tag icon={<CalendarOutlined />}>
				{schedule?.start && schedule.end
					? `${format(schedule.start, dateFormat)} - ${format(schedule.end, dateFormat)}`
					: 'Add schedule'}
			</Tag>
		</Popover>
	);
};

export default OfferSchedule;
