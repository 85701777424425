/**
 * External dependencies
 */
import { FormItemProps as AntFormItemProps, Form } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { ReactNode } from 'react';
import classnames from 'classnames/bind';

/**
 * Internal dependencies
 */
import FormItemLabel from './Label';
import classes from './FormItem.module.scss';

const cx = classnames.bind(classes);

export type FormItemProps<Values extends any> = {
	onCopy?: (name: NamePath<Values>) => void;
	layout?: 'vertical' | 'horizontal';
} & AntFormItemProps<Values>;

const FormItem = <Values extends any>({
	children,
	className,
	label,
	layout,
	onCopy,
	...props
}: FormItemProps<Values>): ReactNode => (
	<Form.Item<Values>
		{...props}
		className={cx(className, layout)}
		label={
			onCopy ? (
				<FormItemLabel
					onCopy={() => props.name && onCopy?.(props.name)}
				>
					{label}
				</FormItemLabel>
			) : (
				label
			)
		}
	>
		{children}
	</Form.Item>
);

export default FormItem;
