/**
 * External dependencies
 */
import { Checkbox, Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import OfferFormItem from '@/offer-form/components/OfferFormItem';
import { offerSubtypeOptions } from '@/offers/config';
import OfferFormHeaderField from '../Field';
import classes from './OfferFormHeaderOptions.module.scss';

export type OfferFormHeaderOptionsProps = {
	buyers?: DefaultOptionType[];
	sources?: DefaultOptionType[];
};

const OfferFormHeaderOptions: FC<OfferFormHeaderOptionsProps> = ({
	buyers,
	sources,
}: OfferFormHeaderOptionsProps) => (
	<Flex gap="middle" className={classes.wrap}>
		<OfferFormHeaderField
			name="subtype"
			label="Offer Sub-type"
			options={offerSubtypeOptions}
		/>
		<OfferFormHeaderField name="source" label="Source" options={sources} />
		<OfferFormHeaderField name="sourceOfferId" label="Source Offer ID" />
		<OfferFormHeaderField name="buyer" label="Buyer" options={buyers} />
		<OfferFormItem
			className={classes.checkboxWrap}
			label="Supports mobile"
			layout="horizontal"
			name="supportsMobile"
		>
			<Checkbox />
		</OfferFormItem>
	</Flex>
);

export default OfferFormHeaderOptions;
