/**
 * Internal dependencies
 */
import {
	ActionCreator,
	Action,
	PlainActionCreator,
	PlainAction,
} from './types';

export const isAction = <P>(
	action: PlainAction,
	actionCreator: ActionCreator<P> | PlainActionCreator
): action is Action<P> => action.type === actionCreator.type;
