/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { forgetAuthToken } from '@/cloudflare/functions';
import { useAuthState } from '@/state';

const useForgetToken = () => {
	const { logOut } = useAuthState();

	return useCallback(async () => {
		await forgetAuthToken();
		logOut();
	}, [logOut]);
};

export default useForgetToken;
