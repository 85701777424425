/**
 * External dependencies
 */
import {
	CopyOutlined,
	DownloadOutlined,
	InboxOutlined,
} from '@ant-design/icons';
import { FC } from 'react';
import { Flex } from 'antd';

/**
 * Internal dependencies.
 */
import { OffersTableDownloadActionProps } from '../OffersTableDownloadAction/OffersTableDownloadAction';
import OffersTableActionButton from '../OffersTableActionButton';
// import OffersTableDownloadAction from '../OffersTableDownloadAction';

export type OffersTableActionsProps = {
	onArchive: () => void;
	onCopy: () => void;
	onDownload: OffersTableDownloadActionProps['onClick'];
};

/**
 * Offers Table Actions component.
 */
const OffersTableActions: FC<OffersTableActionsProps> = ({
	onArchive,
	onCopy,
	onDownload,
}) => (
	<Flex gap={4}>
		<OffersTableActionButton onClick={onCopy} type="text" title="Copy">
			<CopyOutlined />
		</OffersTableActionButton>
		<OffersTableActionButton
			onClick={() => onDownload('metadata')}
			type="text"
			title="Download"
		>
			<DownloadOutlined />
		</OffersTableActionButton>
		{/* <OffersTableDownloadAction onClick={onDownload} /> */}
		<OffersTableActionButton
			onClick={onArchive}
			type="text"
			title="Archive"
		>
			<InboxOutlined />
		</OffersTableActionButton>
	</Flex>
);

export default OffersTableActions;
