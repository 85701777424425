/**
 * Internal dependencies
 */
import { isAction } from '@/store/utils';
import { Reducer } from '@/store/types';
import { setOffersListSorting } from '@/store/actions';
import { SortingConfig } from '@/types';
import initialState from '@/store/initialState';

const sorting: Reducer<SortingConfig> = (
	state = initialState.offersList.sorting,
	action
) =>
	isAction(action, setOffersListSorting)
		? {
				...state,
				...action.payload,
			}
		: state;

export default sorting;
