/**
 * Internal dependencies
 */
import api from './api';

export const {
	useGetBuyersQuery,
	useGetOffersQuery,
	useGetSourcesQuery,
	useLazyGetBuyersQuery,
	useLazyGetOffersQuery,
	useLazyGetSourcesQuery,
	usePatchOfferMutation,
	usePostOffersBoostsMutation,
	usePrefetch,
} = api;
