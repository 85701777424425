/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ContainerProps } from '@/types';
import { useForgetToken } from '@/auth/hooks';
import { useUserData } from '@/state';

type UserData = Partial<ReturnType<typeof useUserData>> & {
	logOut: () => void;
};

type UserContainerProps = ContainerProps<UserData>;

const UserContainer: FC<UserContainerProps> = ({ children }) => {
	const data = useUserData();
	const forgetToken = useForgetToken();

	return children({
		...data,
		logOut: forgetToken,
	});
};

export default UserContainer;
