/**
 * External dependencies
 */
import { FC } from 'react';
import { snake } from 'radash';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import {
	useFiltersContainer,
	useOfferActionsContainer,
	useOffersContainer,
	useOffersPaginationContainer,
	useOffersSelectionContainer,
	useOffersSortingContainer,
} from '../containers';
import { OffersHeader, OffersTable, OffersTableHeader } from '../components';
import { offerStatuses, prepareOfferForDisplay } from '@/offers';
import { useNewBoostContainer } from '@/offers/containers';
import { Route } from '@/router/types';

const OffersManagementPage: FC = () => {
	const navigate = useNavigate();
	const { filters, setFilters, values } = useFiltersContainer();
	const { page, perPage, setPerPage, setPage } =
		useOffersPaginationContainer();
	const { order, orderBy, setSorting } = useOffersSortingContainer();
	const { resetSelection, selection, setSelection } =
		useOffersSelectionContainer();

	const offersQueryProps = {
		page,
		perPage,
		filters: values,
		order,
		orderBy,
	};

	const { isFetching, offers, shouldShowLoader, totalCount } =
		useOffersContainer(offersQueryProps);

	const { handleAction } = useOfferActionsContainer({
		offers,
		offersQueryProps,
	});

	const { boost, modal } = useNewBoostContainer({
		onDone: resetSelection,
	});

	return (
		<Space direction="vertical" size="large" style={{ width: '100%' }}>
			<OffersHeader
				filters={filters}
				onChange={setFilters}
				statuses={offerStatuses}
				values={values}
			/>
			<OffersTable
				loading={shouldShowLoader && isFetching}
				offers={offers?.map(prepareOfferForDisplay)}
				onAction={(type, _, { key }) => handleAction(type, key)}
				onChange={(_, __, sorter) => {
					const _order = Array.isArray(sorter)
						? sorter[0].order
						: sorter.order;

					const newOrder = _order === 'descend' ? 'desc' : 'asc';

					const newOrderBy = _order
						? snake(
								(Array.isArray(sorter)
									? sorter[0].columnKey
									: sorter.columnKey) as string
							)
						: null;

					setSorting({
						order: newOrder,
						orderBy: newOrderBy,
					});
				}}
				pagination={{
					current: page,
					onChange: (newPage) => setPage(newPage),
					onShowSizeChange: (_, size) => setPerPage(size),
					pageSize: perPage,
					total: totalCount,
					showTotal: (total, range) =>
						`${range[0]}-${range[1]} from ${total}`,
				}}
				rowSelection={{
					onChange: (_, rows) =>
						setSelection(rows.map(({ key }) => key)),
					preserveSelectedRowKeys: true,
					selectedRowKeys: selection,
				}}
				title={() => (
					<OffersTableHeader
						hasSelection={!!selection.length}
						onAction={(type) => {
							if (type === 'boost') {
								boost(selection);
								return;
							}

							resetSelection();

							if (type === 'create') {
								navigate(Route.NewOffer);
								return;
							}

							handleAction(type, selection);
						}}
					/>
				)}
			/>
			{modal}
		</Space>
	);
};

export default OffersManagementPage;
