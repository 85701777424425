/**
 * External dependencies
 */
import { Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import OfferFormItem from '@/offer-form/components/OfferFormItem';
import { OfferFormValues } from '@/offer-form/types';

/**
 * Internal dependencies
 */

type OfferFormHeaderFieldProps = {
	name: keyof OfferFormValues;
	label: string;
	options?: DefaultOptionType[];
};

const OfferFormHeaderField: FC<OfferFormHeaderFieldProps> = ({
	name,
	label,
	options,
}) => (
	<OfferFormItem label={label} name={name}>
		{options ? <Select options={options} /> : <Input />}
	</OfferFormItem>
);

export default OfferFormHeaderField;
