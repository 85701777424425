/**
 * Internal dependencies
 */
import { createAction, createPlainAction } from '@/store/action-creators';
import { OfferFilters } from '@/offers';
import { PaginationData, SortingConfig } from '@/types';
import {
	RESET_OFFERS_LIST_SELECTION,
	SET_OFFERS_LIST_FILTERS,
	SET_OFFERS_LIST_PAGINATION,
	SET_OFFERS_LIST_SELECTION,
	SET_OFFERS_LIST_SORTING,
} from '../action-types';

export const setOffersListFilters = createAction<Partial<OfferFilters>>(
	SET_OFFERS_LIST_FILTERS
);

export const setOffersListPagination = createAction<Partial<PaginationData>>(
	SET_OFFERS_LIST_PAGINATION
);

export const setOffersListSorting = createAction<Partial<SortingConfig>>(
	SET_OFFERS_LIST_SORTING
);

export const setOffersListSelection = createAction<string[]>(
	SET_OFFERS_LIST_SELECTION
);

export const resetOffersListSelection = createPlainAction(
	RESET_OFFERS_LIST_SELECTION
);
