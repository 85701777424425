/**
 * External dependencies
 */
import {
	CopyOutlined,
	DeleteOutlined,
	DownloadOutlined,
	PlayCircleOutlined,
	ThunderboltOutlined,
} from '@ant-design/icons';
import { OffersFormHeaderActionConfig } from './types';

export const actions: OffersFormHeaderActionConfig[] = [
	{
		type: 'preview',
		icon: <PlayCircleOutlined />,
	},
	{
		type: 'boost',
		icon: <ThunderboltOutlined />,
	},
	{
		type: 'copy',
		icon: <CopyOutlined />,
	},
	{
		type: 'download',
		icon: <DownloadOutlined />,
	},
	{
		type: 'delete',
		icon: <DeleteOutlined />,
	},
];
