/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { useOffersListPagination } from '@/state';
import { ContainerFn, PaginationData } from '@/types';

type UsePaginationContainer = ContainerFn<
	PaginationData & {
		setPage: (page: number) => void;
		setPagination: (pagination: Partial<PaginationData>) => void;
		setPerPage: (perPage: number) => void;
	}
>;

const useOffersPaginationContainer: UsePaginationContainer = () => {
	const [pagination, setPagination] = useOffersListPagination();

	return {
		...pagination,
		setPage: useCallback((page: number) => setPagination({ page }), []),
		setPerPage: useCallback(
			(perPage: number) => setPagination({ perPage }),
			[]
		),
		setPagination,
	};
};

export default useOffersPaginationContainer;
