/**
 * External dependencies
 */
import { createApi } from '@reduxjs/toolkit/query/react';

/**
 * Internal dependencies
 */
import { baseQuery } from './baseQuery';
import { getTagsForList } from './helpers';
import { Options, ResponseData } from './types';
import { replaceByValue } from '@/utils';

const api = createApi({
	baseQuery,
	tagTypes: ['Offers', 'Sources', 'Buyers'],
	endpoints: (builder) => ({
		getOffers: builder.query<
			{ items: ResponseData<'get', '/offers'>; totalCount: number },
			Options<'get', '/offers'> | void
		>({
			query: (args) => ({
				method: 'get',
				path: '/offers',
				args,
			}),
			transformResponse: (baseResponse, meta) => {
				// TODO: read totalCount from header
				return { items: baseResponse as any, totalCount: 99 };
			},
			providesTags: (result) => getTagsForList('Offers', result?.items),
		}),
		getSources: builder.query<
			ResponseData<'get', '/sources'>,
			Options<'get', '/sources'> | void
		>({
			query: (args) => ({
				method: 'get',
				path: '/sources',
				args,
			}),
		}),
		getBuyers: builder.query<
			ResponseData<'get', '/buyers'>,
			Options<'get', '/buyers'> | void
		>({
			query: (args) => ({
				method: 'get',
				path: '/buyers',
				args,
			}),
		}),
		patchOffer: builder.mutation<
			ResponseData<'patch', '/offers/{offer_id}'>,
			Options<'patch', '/offers/{offer_id}'> & {
				offersQueryArgs?: Options<'get', '/offers'>;
			}
		>({
			query: ({ offersQueryArgs, ...args }) => ({
				method: 'patch',
				path: '/offers/{offer_id}',
				args,
			}),
			onQueryStarted: async (
				{ offersQueryArgs, params, body },
				{ dispatch, queryFulfilled }
			) => {
				const result = dispatch(
					api.util.updateQueryData(
						'getOffers',
						offersQueryArgs,
						(draft) => {
							const item = draft.items?.find(
								({ id }) => id === params.path.offer_id
							);

							if (!item) {
								return;
							}

							Object.assign(
								draft.items!,
								replaceByValue(draft.items!, item, {
									...item,
									...body,
								})
							);
						}
					)
				);

				try {
					await queryFulfilled;
				} catch (error) {
					result.undo();
				}
			},
			invalidatesTags: (
				_,
				__,
				{
					params: {
						path: { offer_id },
					},
				}
			) => [{ type: 'Offers', id: offer_id }],
		}),
		postOffersBoosts: builder.mutation<
			ResponseData<'post', '/offers/{offer_id}/boosts'>,
			Options<'post', '/offers/{offer_id}/boosts'>
		>({
			query: (args) => ({
				method: 'post',
				path: '/offers/{offer_id}/boosts',
				args,
			}),
			invalidatesTags: (
				_,
				__,
				{
					params: {
						path: { offer_id },
					},
				}
			) => [{ type: 'Offers', id: offer_id }],
		}),
	}),
});

export default api;
