// Auth
export const LOG_OUT = 'LOG_OUT';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

// User
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

// Offers List
export const RESET_OFFERS_LIST_SELECTION = 'RESET_OFFERS_LIST_SELECTION';
export const SET_OFFERS_LIST_FILTERS = 'SET_OFFERS_LIST_FILTERS';
export const SET_OFFERS_LIST_PAGINATION = 'SET_OFFERS_LIST_PAGINATION';
export const SET_OFFERS_LIST_SELECTION = 'SET_OFFERS_LIST_SELECTION';
export const SET_OFFERS_LIST_SORTING = 'SET_OFFERS_LIST_SORTING';

// Offer edit
export const SET_EDITED_OFFER = 'SET_EDITED_OFFER';
export const RESET_EDITED_OFFER = 'RESET_EDITED_OFFER';
