/**
 * External dependencies
 */
import { Progress } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import { formatPercentage } from '@/utils';
import { OfferForDisplay } from '@/offers';

export type CompletionColumnProps = NonNullable<OfferForDisplay['completion']>;

/**
 * Offers Table Completion Column component.
 */
const CompletionColumn: FC<CompletionColumnProps> = ({ completed, total }) => {
	if (!completed || !total) {
		return null;
	}

	const completionPercentage = completed / total;

	return (
		<>
			<div>
				{completed}/{total} ({formatPercentage(completionPercentage)})
			</div>
			<Progress
				percent={completionPercentage * 100}
				strokeColor="green"
				showInfo={false}
			/>
		</>
	);
};

export default CompletionColumn;
