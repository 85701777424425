/**
 * External dependencies
 */
import { FC, PropsWithChildren, Suspense } from 'react';

/**
 * Internal dependencies
 */
import TokenHandler from '../TokenHandler';

type AuthHandlerProps = PropsWithChildren;

const AuthHandler: FC<AuthHandlerProps> = ({ children }) => (
	<Suspense fallback="loading...">
		<TokenHandler />
		{children}
	</Suspense>
);

export default AuthHandler;
