/**
 * External dependencies
 */
import { Button } from 'antd';
import { ComponentProps, FC } from 'react';

/**
 * Internal dependencies.
 */
import classes from './OffersTableActionButton.module.scss';

type OffersTableActionButtonProps = ComponentProps<typeof Button>;

/**
 * Offers Table Action Button component.
 */
const OffersTableActionButton: FC<OffersTableActionButtonProps> = ({
	className,
	...props
}) => <Button className={`${className} ${classes.button}`} {...props} />;

export default OffersTableActionButton;
