/**
 * External dependencies
 */
import { configureStore } from '@reduxjs/toolkit';
import {
	type TypedUseSelectorHook,
	useDispatch as useReduxDispatch,
	useSelector as useReduxSelector,
	useStore as useReduxStore,
} from 'react-redux';
import { enhancer as storybookAddonEnhancer } from '@dreamworld/addon-redux';

/**
 * Internal dependencies
 */
import { api } from '@/api';
import { Dispatch, RootState } from './types';
import initialState from './initialState';
import rootReducer from './rootReducer';

export const store = configureStore({
	preloadedState: initialState,
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(api.middleware),
	enhancers: process.env.STORYBOOK
		? (getDefaultEnhancers) =>
				getDefaultEnhancers().concat(storybookAddonEnhancer)
		: undefined,
});

export const useDispatch = () => useReduxDispatch<Dispatch>();

export const useStore = () => useReduxStore<RootState>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export * from './action-creators';
export * from './actions';
