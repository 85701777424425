/**
 * External dependencies
 */
import { Modal, Form, FormProps } from 'antd';
import { ReactNode, useId } from 'react';

export type FormModalProps<T extends any> = {
	children: ReactNode;
	form?: Omit<FormProps, 'onValuesChange'>;
	okText: string;
	onCancel: () => void;
	onSubmit: ((formData: T) => void) | ((formData: T) => Promise<void>);
	open: boolean;
	subtitle?: ReactNode;
	title: string;
	loading?: boolean;
};

const FormModal = <T extends any>({
	children,
	form: formProps,
	onSubmit,
	subtitle,
	loading,
	...modalProps
}: FormModalProps<T>): ReactNode => {
	const id = useId();
	const [form] = Form.useForm();

	return (
		<Modal
			{...modalProps}
			cancelButtonProps={{ disabled: loading }}
			okButtonProps={{ form: id, htmlType: 'submit', loading }}
		>
			<p>{subtitle}</p>
			<Form
				{...formProps}
				form={form}
				id={id}
				disabled={loading}
				onFinish={async (data) => {
					await onSubmit(data);
					form.resetFields();
				}}
			>
				{children}
			</Form>
		</Modal>
	);
};

export default FormModal;
