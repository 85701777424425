/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { actions } from './config';
import { HeaderActions } from '@/components';
import { OfferFormTitleProps } from '../OfferFormTitle/OfferFormTitle';
import { OffersFormHeaderAction } from './types';
import { offerStatuses } from '@/offers';
import classes from './OfferFormHeader.module.scss';
import Header from '@/components/Header';
import OfferFormTitle from '../OfferFormTitle';
import OfferFormHeaderOptions, {
	OfferFormHeaderOptionsProps,
} from './Options/OfferFormHeaderOptions';

type OfferFormHeaderProps = {
	title?: string;
	onClose?: () => void;
	onAction?: (action: OffersFormHeaderAction) => void;
} & Pick<
	OfferFormTitleProps,
	'onScheduleChange' | 'onStatusChange' | 'schedule' | 'status'
> &
	OfferFormHeaderOptionsProps;

const OfferFormHeader: FC<OfferFormHeaderProps> = ({
	onAction,
	onClose,
	title,
	sources,
	buyers,
	...titleProps
}) => (
	<>
		<Header className={classes.header}>
			<OfferFormTitle statuses={offerStatuses} {...titleProps}>
				{title}
			</OfferFormTitle>
			<HeaderActions
				actions={actions}
				onAction={onAction}
				onClose={onClose}
			/>
		</Header>
		<Header sticky={false} className={classes.subHeader}>
			<OfferFormHeaderOptions {...{ buyers, sources }} />
		</Header>
	</>
);

export default OfferFormHeader;
