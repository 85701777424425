/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

/**
 * Internal dependencies.
 */
import classes from './FormItemLabel.module.scss';

type FormItemLabelProps = PropsWithChildren<{
	onCopy?: () => void;
}>;

const FormItemLabel: FC<FormItemLabelProps> = ({ children, onCopy }) => (
	<span className={classes.label}>
		{children}
		<span className={classes.icon} onClick={onCopy}>
			<Tooltip title="Copy value" placement="top">
				<CopyOutlined />
			</Tooltip>
		</span>
	</span>
);

export default FormItemLabel;
