/**
 * External dependencies
 */
import { assert, is } from 'tsafe';
import { CheckOutlined } from '@ant-design/icons';
import { Flex, Tag } from 'antd';
import { ReactNode } from 'react';

export type OffersHeaderStatusesProps<K extends string> = {
	options: Record<K, string>;
	values: K[];
	onChange: (values: K[]) => void;
};

const { CheckableTag } = Tag;

/**
 * Offers Table Header Statuses component.
 */
const OffersHeaderStatuses = <K extends string>({
	options,
	values,
	onChange,
}: OffersHeaderStatusesProps<K>): ReactNode => {
	const allOptions = [
		{ label: 'All', value: 'all', checked: !values.length } as const,
		...Object.entries(options).map(([value, label]) => {
			assert(is<K>(value));
			assert(is<string>(label));

			return {
				value,
				label,
				checked: !!values?.includes(value),
			};
		}),
	];

	return (
		<Flex gap="small">
			{allOptions.map(({ value, label, checked }) => (
				<CheckableTag
					key={value}
					checked={checked}
					onChange={(_checked) =>
						onChange(
							_checked
								? value === 'all'
									? []
									: [...values, value]
								: values.filter((status) => status !== value)
						)
					}
				>
					<Flex gap="small">
						{checked && <CheckOutlined />}
						{label}
					</Flex>
				</CheckableTag>
			))}
		</Flex>
	);
};

export default OffersHeaderStatuses;
