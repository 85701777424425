/**
 * External dependencies
 */
import { useSelector } from 'react-redux';

/**
 * Internal dependencies
 */
import { selectAuthTokenData } from '@/store/selectors';

const useTokenData = () => useSelector(selectAuthTokenData);

export default useTokenData;
