/**
 * Internal dependencies
 */
import { isAction } from '../utils';
import { Reducer } from '@/store/types';
import { resetUser, setUser } from '@/store/actions';
import initialState from '@/store/initialState';

const user: Reducer<object | null> = (state = initialState.user, action) => {
	if (isAction(action, setUser)) {
		return action.payload;
	}

	if (isAction(action, resetUser)) {
		return null;
	}

	return state;
};

export default user;
