/**
 * Internal dependencies
 */
import { isAction } from '@/store/utils';
import { Reducer } from '@/store/types';
import {
	resetOffersListSelection,
	setOffersListSelection,
} from '@/store/actions';
import initialState from '@/store/initialState';

const selection: Reducer<string[]> = (
	state = initialState.offersList.selection,
	action
) =>
	isAction(action, setOffersListSelection)
		? action.payload
		: isAction(action, resetOffersListSelection)
			? []
			: state;

export default selection;
