/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { setOffersListSorting, useDispatch, useSelector } from '@/store';
import { SortingConfig } from '@/types';

const useOffersListSorting = () => {
	const dispatch = useDispatch();

	return [
		useSelector((state) => state.offersList.sorting),
		useCallback(
			(sorting: Partial<SortingConfig>) =>
				dispatch(setOffersListSorting(sorting)),
			[dispatch]
		),
	] as const;
};

export default useOffersListSorting;
