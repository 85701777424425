/**
 * Internal dependencies
 */
import type { AppState, RootState } from './types';

export const getInitialState = () =>
	({
		auth: {
			token: null,
			initial: true,
		},
		editedOffer: null,
		offersList: {
			filters: {
				buyer: [],
				source: [],
				status: [],
				subtype: [],
			},
			pagination: {
				page: 1,
				perPage: 10,
			},
			selection: [],
			sorting: {
				order: 'asc',
				orderBy: null,
			},
		},
		user: null,
	}) satisfies AppState as any as RootState;

const initialState = getInitialState();

export default initialState;
