export const removeEmpty = <T extends {}>(obj: T): Partial<T> =>
	Object.fromEntries(
		Object.entries(obj)
			.filter(
				([, v]) =>
					v &&
					(!Array.isArray(v) || v.length) &&
					(typeof v !== 'object' || Object.keys(v).length)
			)
			.map(([k, v]) => [
				k,
				v && typeof v === 'object' && !Array.isArray(v)
					? removeEmpty(v)
					: v,
			])
	) as Partial<T>;
