/**
 * Formats given value to USD currency.
 */
export const formatCurrency = (value: number) =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	}).format(value);

/**
 * Formats given value to percentage.
 *
 * This function expects the value to be decimal representation of percentage.
 */
export const formatPercentage = (value: number) =>
	new Intl.NumberFormat('en-US', {
		style: 'percent',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	}).format(value);
