/**
 * Internal dependencies
 */
import { OptionalSpread } from '@/types';
import {
	ActionCreator,
	ActionType,
	PlainActionCreator,
	ThunkAction,
} from './types';

export const createPlainAction = (type: ActionType): PlainActionCreator =>
	Object.assign(() => ({ type }), { type });

export const createAction = <P = any>(type: ActionType): ActionCreator<P> =>
	Object.assign((payload: P) => ({ type, payload }), { type });

export const createThunkAction = <P = any, T = P, R = void>(
	callback: (...args: OptionalSpread<P>) => ThunkAction<T, R>
) => callback;
