/**
 * External dependencies
 */
import { FC } from 'react';
import { Table, TableProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';

/**
 * Internal dependencies.
 */
import { formatCurrency, formatPercentage } from '@/utils';
import { formatDate, getSubtypeName } from './utils';
import { OfferAction, OfferForDisplay } from '@/offers';
import CompletionColumn from './CompletionColumn';
import OffersTableActions from '../OffersTableActions';
import StatusColumn from './StatusColumn';

export type OffersTableProps = TableProps<OfferForDisplay> & {
	offers?: OfferForDisplay[];
	onSelect?: TableRowSelection<OfferForDisplay>['onChange'];
	onAction: (
		action: OfferAction,
		subtype: string | null,
		record: OfferForDisplay
	) => void;
};

const Column = Table.Column<OfferForDisplay>;

/**
 * Offers Table component.
 */
const OffersTable: FC<OffersTableProps> = ({
	offers,
	onAction,
	onSelect,
	pagination,
	rowSelection,
	...tableProps
}) => (
	<Table
		rowSelection={{ type: 'checkbox', onChange: onSelect, ...rowSelection }}
		dataSource={offers}
		pagination={{
			position: ['bottomRight'],
			showSizeChanger: true,
			...pagination,
		}}
		{...tableProps}
	>
		<Column
			dataIndex="offerName"
			key="name"
			sorter={true}
			title="Offer name"
		/>
		<Column
			title="Sub-type"
			sorter={true}
			key="subtype"
			render={(_, { offerSubtype }) =>
				offerSubtype && getSubtypeName(offerSubtype)
			}
		/>
		<Column
			title="Start date"
			sorter={true}
			key="startDate"
			render={(_, { startDate }) => startDate && formatDate(startDate)}
		/>
		<Column
			title="End date"
			sorter={true}
			key="endDate"
			render={(_, { endDate }) => endDate && formatDate(endDate)}
		/>
		<Column
			title="Status"
			sorter={true}
			key="status"
			render={(_, { status, isBoosted }) => (
				<StatusColumn {...{ status, isBoosted }} />
			)}
		/>
		<Column
			title="Fixed"
			render={(_, { fixedCPI }) => formatCurrency(fixedCPI ?? 0)}
		/>
		<Column
			title="Variable"
			render={(_, { variableCPI }) => formatPercentage(variableCPI ?? 0)}
		/>
		<Column
			title="Completion"
			render={(_, record) => <CompletionColumn {...record.completion} />}
		/>
		<Column
			title="Actions"
			render={(_, record) => (
				<OffersTableActions
					onArchive={() => onAction('end', null, record)}
					onCopy={() => onAction('copy', null, record)}
					onDownload={(action) =>
						onAction('download', action, record)
					}
				/>
			)}
		/>
	</Table>
);

export default OffersTable;
