/**
 * Internal dependencies
 */
import { AuthState } from '../reducers/auth';
import { createAction, createPlainAction } from '@/store/action-creators';
import { LOG_OUT, SET_AUTH_TOKEN } from '@/store/action-types';

export const logOut = createPlainAction(LOG_OUT);

export const setAuthToken = createAction<AuthState['token']>(SET_AUTH_TOKEN);
