import { OfferBase } from '@/offers';
import { OfferFormValues } from './types';

export const mapOfferToFormValues = (
	offer: Partial<OfferBase>
): Partial<OfferFormValues> => ({
	subtype: offer.offer_subtype,
	source: offer.source_id,
	sourceOfferId: offer.source_offer_id ?? undefined,
	buyer: offer.buyer?.id,
});

export const mapFormValuesToOffer = (values: OfferFormValues): OfferBase => ({
	buyer: values.buyer
		? {
				id: values.buyer,
				created: new Date().toISOString(),
			}
		: undefined,
	fixed_cpi: 1,
	is_digital: true,
	is_intent_registered: true,
	offer_name: 'New survey',
	offer_subtype: values.subtype,
	offer_type: 'survey',
	reversal_days: 0,
	source_id: values.source || '',
	source_offer_id: values.sourceOfferId,
	status: 'draft',
	variable_cpi: 1,
});
