/**
 * Internal dependencies
 */
import { createAction, createPlainAction } from '@/store/action-creators';
import { SET_USER, RESET_USER } from '@/store/action-types';
import { User } from '@/types/user';

export const setUser = createAction<User>(SET_USER);

export const resetUser = createPlainAction(RESET_USER);
