/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { useDispatch, useSelector } from '@/store';
import { OfferBase } from '@/offers';
import { setEditedOffer } from '@/store/actions/editedOffer';

const useEditedOffer = () => {
	const dispatch = useDispatch();

	return [
		useSelector((state) => state.editedOffer),
		useCallback(
			(offer: Partial<OfferBase>) => dispatch(setEditedOffer(offer)),
			[dispatch]
		),
	] as const;
};

export default useEditedOffer;
