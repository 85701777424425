/**
 * Internal dependencies
 */
import { isAction } from '@/store/utils';
import { PaginationData } from '@/types';
import { Reducer } from '@/store/types';
import { setOffersListPagination } from '@/store/actions';
import initialState from '@/store/initialState';

const pagination: Reducer<PaginationData> = (
	state = initialState.offersList.pagination,
	action
) =>
	isAction(action, setOffersListPagination)
		? {
				...state,
				...action.payload,
			}
		: state;

export default pagination;
