/**
 * External dependencies
 */
import { App as AntApp } from 'antd';
import { FC, StrictMode } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { createRouter } from '@/router/utils';
import { routes } from '@/router/routes';
import { store } from '@/store';
import AntConfig from '../AntConfig';

type AppProps = {};

const App: FC<AppProps> = () => (
	<StrictMode>
		<Provider store={store}>
			<AntConfig>
				<AntApp>
					<RouterProvider router={createRouter(routes)} />
				</AntApp>
			</AntConfig>
		</Provider>
	</StrictMode>
);

export default App;
