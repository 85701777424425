/**
 * External dependencies
 */
import { ComponentType } from 'react';

export enum Route {
	Home = '/',
	NewOffer = '/new-offer',
}

export type Routes = Record<Route, ComponentType>;
