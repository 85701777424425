/**
 * External dependencies
 */
import { ActionFromReducersMapObject, combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import { api } from '@/api';
import { getInitialState } from './initialState';
import { isAction } from './utils';
import { logOut } from './actions';
import * as reducers from './reducers';

const combinedReducer = combineReducers({
	...reducers,
	[api.reducerPath]: api.reducer,
});

export const rootReducer = (
	state: ReturnType<typeof combinedReducer> | undefined,
	action: ActionFromReducersMapObject<typeof reducers>
): ReturnType<typeof combinedReducer> =>
	combinedReducer(
		isAction(action, logOut) ? getInitialState() : state,
		action
	);

export default rootReducer;
