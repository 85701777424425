/**
 * External dependencies
 */
import { createSelector } from '@reduxjs/toolkit';

/**
 * Internal dependencies
 */
import { decodeToken } from '@/auth/utils/token';
import { RootState } from '../types';

export const selectUserData = createSelector(
	(state: RootState) => state.auth.token,
	(token) => {
		if (!token) {
			return null;
		}

		const payload = decodeToken(token);

		return payload
			? {
					displayName:
						payload.given_name || payload.family_name
							? [payload.given_name, payload.family_name].join(
									' '
								)
							: payload.name || payload.email || '',
					email: payload.email,
					firstName: payload.given_name,
					id: payload.profile_id,
					lastName: payload.family_name,
					name: payload.name,
				}
			: null;
	}
);
