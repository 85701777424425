/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { getAuthToken } from '@/cloudflare/functions';
import { getAuthUrl } from '@/auth/utils/urls';
import { useAuthState } from '@/state';

const useFetchToken = () => {
	const { initial, setToken, token } = useAuthState();

	return {
		fetchToken: useCallback(
			() =>
				new Promise<void>((resolve, reject) =>
					setTimeout(async () => {
						const newToken = await getAuthToken();

						setToken(newToken);

						if (!newToken) {
							window.location.href = getAuthUrl();
							return;
						}

						resolve();
					})
				),
			[setToken]
		),
		shouldFetch: !token && initial,
	};
};

export default useFetchToken;
