/**
 * External dependencies
 */
import { App } from 'antd';
import { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { Offer, OffersQueryProps, getOffersQueryArgs } from '@/offers';
import { useGetOffersQuery } from '@/api';
import { useHasChanged } from '@/hooks';

type UseOffersContainer = ContainerFn<
	{
		isError: boolean;
		isFetching: boolean;
		isLoading: boolean;
		isSuccess: boolean;
		offers?: Offer[];
		shouldShowLoader: boolean;
		totalCount?: number;
	},
	OffersQueryProps
>;

const useOffersContainer: UseOffersContainer = (args) => {
	const [shouldShowLoader, setShouldShowLoader] = useState(true);
	const argsChanged = useHasChanged(args);

	const { notification } = App.useApp();

	const { data, isLoading, isFetching, isSuccess, isError, error } =
		useGetOffersQuery(getOffersQueryArgs(args));

	useEffect(() => {
		if (!isFetching) {
			setShouldShowLoader(false);
		}
	}, [isFetching]);

	useEffect(() => {
		if (argsChanged) {
			setShouldShowLoader(true);
		}
	}, [argsChanged]);

	useEffect(() => {
		if (error) {
			notification.error({
				message: 'Something went wrong while loading offers',
				description: 'detail' in error ? error.detail : error.message,
			});
		}
	}, [error, notification]);

	return {
		isError,
		isFetching,
		isLoading,
		isSuccess,
		offers: !isError ? data?.items : undefined,
		shouldShowLoader,
		totalCount: data?.totalCount,
	};
};

export default useOffersContainer;
