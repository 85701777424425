/**
 * External dependencies
 */
import { Flex } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import { getHeaderActions } from './config';
import { HeaderActions, HeaderSearch } from '@/components';
import { OffersTableHeaderAction } from './types';

export type OffersTableHeaderProps = {
	hasSelection?: boolean;
	onAction?: (type: OffersTableHeaderAction) => void;
	onSearch?: (search: string) => void;
};

/**
 * Offers Table Header component.
 */
const OffersTableHeader: FC<OffersTableHeaderProps> = ({
	hasSelection = false,
	onAction,
	onSearch,
}) => (
	<Flex justify="space-between" flex="1">
		{onSearch ? <HeaderSearch onChange={onSearch} /> : <span />}
		<HeaderActions
			actions={getHeaderActions(hasSelection)}
			onAction={onAction}
		/>
	</Flex>
);

export default OffersTableHeader;
