/**
 * External dependencies.
 */
import { format, parseISO } from 'date-fns';

/**
 * Internal dependencies.
 */
import { OfferSubtype, offerSubtypes } from '@/offers';

/**
 * Formats the date instance.
 */
export const formatDate = (date: string) =>
	format(parseISO(date), 'MM/dd/yyyy, hh:mm a');

/**
 * Gets the offer subtype name.
 */
export const getSubtypeName = (subtype: OfferSubtype) => offerSubtypes[subtype];
