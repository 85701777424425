/**
 * External dependencies
 */
import { App } from 'antd';
import { useCallback, useMemo } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import {
	Offer,
	OfferAction,
	OfferStatus,
	OffersQueryProps,
	downloadOffer,
} from '@/offers';
import { useUpdateOfferContainer } from '@/offers/containers';

type UseOfferActionsContainer = ContainerFn<
	{
		handleAction: (type: OfferAction, ids: string | string[]) => void;
	},
	{ offers?: Offer[]; offersQueryProps?: OffersQueryProps }
>;

const useOfferActionsContainer: UseOfferActionsContainer = ({
	offers,
	offersQueryProps,
}) => {
	const { message } = App.useApp();
	const { update } = useUpdateOfferContainer({ offersQueryProps });

	const setStatus = useCallback(
		async (ids: string[], status: OfferStatus) => {
			const results = await Promise.allSettled(
				ids.map((id) => update({ id, status }))
			);

			const archivedCount = results.filter(
				(result) => result.status === 'fulfilled'
			).length;

			const errorsCount = results.filter(
				(result) => result.status === 'rejected'
			).length;

			if (archivedCount) {
				message.success(
					archivedCount === 1
						? errorsCount
							? `1 Offer status changed to ${status}`
							: `Offer status changed to ${status}`
						: `${archivedCount} Offers status changed to ${status}`
				);
			}

			if (errorsCount) {
				message.error(
					`'Status change failed for ${errorsCount} ${errorsCount > 1 ? 'offers' : 'offer'}.`
				);
			}
		},
		[message, update]
	);

	const handleDownload = useCallback(
		(ids: string[]) =>
			ids.forEach((offerId) => {
				const offer = offers?.find(({ id }) => id === offerId);

				if (!offer) {
					message.error(`Offer not found (id: ${offerId})`);
					return;
				}

				downloadOffer(offer);
			}),
		[message, offers]
	);

	const actions = useMemo(
		(): Record<OfferAction, (ids: string[]) => void> => ({
			end: (ids) => setStatus(ids, 'closed'),
			// TODO: implement copy action.
			copy: (ids) => {},
			download: handleDownload,
			pause: (ids) => setStatus(ids, 'paused'),
		}),
		[handleDownload, setStatus]
	);

	return {
		handleAction: useCallback(
			(type, ids) => actions[type](Array.isArray(ids) ? ids : [ids]),
			[actions]
		),
	};
};

export default useOfferActionsContainer;
