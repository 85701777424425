/**
 * External dependencies
 */
import { useEffect, useRef } from 'react';

/**
 * Internal dependencies
 */
import { useTokenData } from '@/state';
import useFetchToken from '../useFetchToken';

const useRefreshToken = () => {
	const { fetchToken } = useFetchToken();
	const data = useTokenData();

	const refreshTimeout = useRef<NodeJS.Timeout | undefined>();

	useEffect(() => {
		if (!data.expiresAt) {
			return;
		}

		clearTimeout(refreshTimeout.current);

		refreshTimeout.current = setTimeout(
			() => fetchToken(),
			data.expiresAt - Date.now()
		);

		return () => clearTimeout(refreshTimeout.current);
	}, [data, fetchToken]);
};

export default useRefreshToken;
