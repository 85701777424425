/**
 * External dependencies
 */
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Flex, MenuProps } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as Avatar } from '@/images/avatar.svg';
import classes from './UserMenu.module.scss';

type UserMenuProps = {
	userName: string;
	items: MenuProps['items'];
};

const UserMenu: FC<UserMenuProps> = ({ userName, items }) => (
	<Dropdown
		className={classes.user}
		menu={{ items }}
		placement="bottomRight"
		trigger={['click']}
	>
		<Flex gap="middle" align="center">
			<Avatar />
			{userName}
			<DownOutlined />
		</Flex>
	</Dropdown>
);

export default UserMenu;
