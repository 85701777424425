/**
 * Internal dependencies
 */
import { removeByValue } from '@/utils';

export const processFiltersSelection = <T extends Record<string, any>>(
	currentValues: T,
	previousValues: T
) =>
	Object.fromEntries(
		Object.entries(currentValues).map(([key, value]) => [
			key,
			Array.isArray(value) &&
			Array.isArray(previousValues[key]) &&
			(!previousValues[key].length || value.includes('all'))
				? !previousValues[key].length
					? removeByValue(value, 'all')
					: []
				: value,
		])
	) as T;
