/**
 * Internal dependencies
 */
import { OfferBase } from '@/offers';
import { createAction, createPlainAction } from '@/store/action-creators';
import { RESET_EDITED_OFFER, SET_EDITED_OFFER } from '@/store/action-types';

export const setEditedOffer =
	createAction<Partial<OfferBase>>(SET_EDITED_OFFER);

export const resetEditedOffer = createPlainAction(RESET_EDITED_OFFER);
