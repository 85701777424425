/**
 * Internal dependencies
 */
import { keycloakClientId, keycloakRealm, keycloakUrl } from '@/data/env';
import { addSearchParams, getAppUrl, normalizeUrl } from '@/utils';

export const getKeycloakOpenIdUrl = (endpoint: string) =>
	`${normalizeUrl(keycloakUrl)}/realms/${keycloakRealm}/protocol/openid-connect/${endpoint}`;

export const getRedirectUrl = () => getAppUrl('auth/callback');

export const getAuthUrl = () =>
	addSearchParams(getKeycloakOpenIdUrl('auth'), {
		response_type: 'code',
		scope: 'openid',
		client_id: keycloakClientId,
		redirect_uri: getRedirectUrl(),
	});
