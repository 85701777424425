/**
 * External dependencies
 */
import { BasicProps } from 'antd/es/layout/layout';
import { FC } from 'react';
import { Flex, Layout } from 'antd';
import classnames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './Header.module.scss';

const cx = classnames.bind(classes);

type HeaderProps = BasicProps & {
	sticky?: boolean;
};

const Header: FC<HeaderProps> = ({
	children,
	className,
	sticky = true,
	...props
}) => (
	<Layout.Header className={cx('header', className, { sticky })} {...props}>
		<Flex justify="space-between" align="center" gap="middle">
			{children}
		</Flex>
	</Layout.Header>
);

export default Header;
