/**
 * External dependencies
 */
import { formatISO, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn, Schedule } from '@/types';
import { useEditedOffer } from '@/state';

type UseOfferScheduleContainer = ContainerFn<
	[Schedule | undefined, (schedule?: Schedule) => void]
>;

const useOfferScheduleContainer: UseOfferScheduleContainer = () => {
	const [offer, setOfferData] = useEditedOffer();

	return [
		useMemo(
			() =>
				offer?.schedule
					? {
							end: offer.schedule.end
								? parseISO(offer.schedule.end).getTime()
								: undefined,
							start: offer.schedule.start
								? parseISO(offer.schedule.start).getTime()
								: undefined,
						}
					: undefined,
			[offer]
		),
		useCallback(
			(schedule) => {
				setOfferData({
					...offer,
					schedule: schedule
						? {
								...offer?.schedule,
								start: schedule.start
									? formatISO(schedule.start)
									: undefined,
								end: schedule.end
									? formatISO(schedule.end)
									: undefined,
							}
						: undefined,
				});
			},
			[offer, setOfferData]
		),
	];
};

export default useOfferScheduleContainer;
