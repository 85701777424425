/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { logOut, setAuthToken, useDispatch, useSelector } from '@/store';

const useAuthState = () => {
	const dispatch = useDispatch();

	return {
		...useSelector((state) => state.auth),
		setToken: useCallback(
			(token: string | null) => dispatch(setAuthToken(token)),
			[dispatch]
		),
		logOut: useCallback(() => dispatch(logOut()), [dispatch]),
	};
};

export default useAuthState;
