/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { OfferStatus } from '@/offers';
import { useEditedOffer } from '@/state';

type UseOfferStatusContainer = ContainerFn<
	[OfferStatus | undefined, (status: OfferStatus | undefined) => void]
>;

const useOfferStatusContainer: UseOfferStatusContainer = () => {
	const [offer, setOfferData] = useEditedOffer();

	return [
		offer?.status,
		useCallback(
			(status: OfferStatus | undefined) =>
				setOfferData({
					...offer,
					status,
				}),
			[offer, setOfferData]
		),
	];
};

export default useOfferStatusContainer;
