/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal
 */
import { useFetchToken, useRefreshToken } from '@/auth/hooks';

const TokenHandler: FC = () => {
	useRefreshToken();

	const { fetchToken, shouldFetch } = useFetchToken();

	if (shouldFetch) {
		throw fetchToken();
	}

	return null;
};

export default TokenHandler;
