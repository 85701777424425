/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn, SortingConfig } from '@/types';
import { useOffersListSorting } from '@/state';

type UseOffersSortingContainer = ContainerFn<
	SortingConfig & {
		serOrder: (order: 'asc' | 'desc') => void;
		setOrderBy: (orderBy: string | null) => void;
		setSorting: (sorting: Partial<SortingConfig>) => void;
	}
>;

const useOffersSortingContainer: UseOffersSortingContainer = () => {
	const [sorting, setSorting] = useOffersListSorting();

	return {
		...sorting,
		serOrder: useCallback(
			(order: 'asc' | 'desc') => setSorting({ order }),
			[]
		),
		setOrderBy: useCallback(
			(orderBy: string | null) => setSorting({ orderBy }),
			[]
		),
		setSorting: useCallback(
			(newSorting: Partial<SortingConfig>) => setSorting(newSorting),
			[]
		),
	};
};

export default useOffersSortingContainer;
