/**
 * Internal dependencies
 */
import { useMemo } from 'react';

const useFilterOptions = (data?: Array<{ id?: string; name?: string }>) =>
	useMemo(
		() =>
			data
				?.filter(({ id, name }) => id && name)
				.map(({ id, name }) => ({
					label: name,
					value: id,
				})) || [],
		[data]
	);

export default useFilterOptions;
