/**
 * External dependencies
 */
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Tag } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import {
	OfferStatus as OfferStatusType,
	OfferStatuses,
	getStatusData,
} from '@/offers';

type OfferStatusProps = {
	statuses?: OfferStatuses;
	currentStatusId?: OfferStatusType;
	onChange?: (statusId: OfferStatusType) => void;
};

const OfferStatus: FC<OfferStatusProps> = ({
	statuses,
	currentStatusId,
	onChange,
}) => {
	const currentStatus = getStatusData(currentStatusId || 'draft');

	const menu: MenuProps = {
		items: statuses
			? Object.entries(statuses).map(([key, label]) => ({
					label,
					key,
				}))
			: [],
		onClick: (menuInfo) => onChange?.(menuInfo.key as OfferStatusType),
	};

	return (
		<Dropdown menu={menu} trigger={['click']} placement="bottomRight" arrow>
			<Tag
				color={currentStatus.backgroundColor}
				style={{
					borderColor: currentStatus.textColor,
					color: currentStatus.textColor,
				}}
				icon={currentStatus.icon}
			>
				{currentStatus.name}
				<DownOutlined />
			</Tag>
		</Dropdown>
	);
};

export default OfferStatus;
