/**
 * Internal dependencies
 */
import { isAction } from '../utils';
import { OfferBase } from '@/offers';
import { Reducer } from '@/store/types';
import { resetEditedOffer, setEditedOffer } from '../actions/editedOffer';
import initialState from '@/store/initialState';

const editedOffer: Reducer<Partial<OfferBase> | null> = (
	state = initialState.editedOffer,
	action
) => {
	return isAction(action, setEditedOffer)
		? { ...state, ...action.payload }
		: isAction(action, resetEditedOffer)
			? null
			: state;
};

export default editedOffer;
