/**
 * External dependencies
 */
import { jwtDecode } from 'jwt-decode';
import { array, lazy, number, object, string } from 'yup';

export const tokenPayloadSchema = object({
	aud: lazy((value) =>
		Array.isArray(value) ? array().of(string().required()) : string()
	),
	email: string().optional(),
	exp: number().optional(),
	family_name: string().optional(),
	given_name: string().optional(),
	iat: number().optional(),
	iss: string().optional(),
	jti: string().optional(),
	name: string().optional(),
	nbf: number().optional(),
	profile_id: string().optional(),
	sub: string().optional(),
});

export const decodeToken = (token: string) =>
	tokenPayloadSchema.validateSync(jwtDecode(token));
