/**
 * External dependencies.
 */
import {
	CloseOutlined,
	FormOutlined,
	PauseOutlined,
	ScheduleOutlined,
	StarOutlined,
} from '@ant-design/icons';

/**
 * Internal dependencies.
 */
import { getCssVar } from '@/utils';
import { offerSubtypes, type OfferStatus } from '@/offers';
import type { OfferStatusConfig } from './types';

export const statusConfig: Record<OfferStatus, OfferStatusConfig> = {
	draft: {
		textColor: undefined,
		backgroundColor: undefined,
		icon: <FormOutlined />,
	},
	scheduled: {
		textColor: undefined,
		backgroundColor: undefined,
		icon: <ScheduleOutlined />,
	},
	available: {
		textColor: getCssVar('succesText'),
		backgroundColor: getCssVar('success10'),
		icon: <StarOutlined />,
	},
	paused: {
		textColor: getCssVar('warningText'),
		backgroundColor: getCssVar('warning10'),
		icon: <PauseOutlined />,
	},
	closed: {
		textColor: undefined,
		backgroundColor: undefined,
		icon: <CloseOutlined />,
	},
};

export const offerSubtypeOptions = Object.entries<string>(offerSubtypes).map(
	([value, label]) => ({
		label,
		value,
	})
);
