/**
 * External dependencies
 */
import { Flex, Form, Select } from 'antd';
import { ReactNode } from 'react';
import classnames from 'classnames/bind';

/**
 * Internal dependencies.
 */
import { DatePicker } from '@/components';
import { OffersFilterConfig } from '../../types';
import classes from './OffersHeaderFilters.module.scss';

const cx = classnames.bind(classes);

export type OffersHeaderFiltersProps<T extends string> = {
	filters: OffersFilterConfig<T>[];
};

/**
 * Offers Table Actions component.
 */
const OffersHeaderFilters = <T extends string>({
	filters,
}: OffersHeaderFiltersProps<T>): ReactNode => (
	<Flex gap="small">
		{filters.map(
			({ allDisabled, name, label, options, multiple, type }) => (
				<Form.Item
					className={cx({
						selectContainer: type !== 'date',
						dateContainer: type === 'date',
					})}
					key={name}
					label={label}
					name={name as string}
				>
					{type === 'date' ? (
						<DatePicker className={classes.dateInput} />
					) : (
						<Select
							allowClear={true}
							maxTagCount="responsive"
							mode={multiple ? 'multiple' : undefined}
							options={[
								{
									label: 'All',
									value: multiple ? 'all' : null,
									disabled: allDisabled,
								},
								...options,
							]}
						/>
					)}
				</Form.Item>
			)
		)}
	</Flex>
);

export default OffersHeaderFilters;
