/**
 * Internal dependencies
 */
import { OfferStatus, OfferSubtype } from './types';

export const offerStatuses: Record<OfferStatus, string> = {
	draft: 'Draft',
	scheduled: 'Scheduled',
	available: 'Available',
	paused: 'Paused',
	closed: 'Closed',
};

export const offerSubtypes: Record<OfferSubtype, string> = {
	'basic-survey': 'Basic Survey',
	'category-coupon': 'Category Coupon',
	'category-sale': 'Category Sale',
	'deal-of-the-day': 'Deal of the Day',
	'diary-study': 'Diary Study',
	'digital-focus-group': 'Digital Focus Group',
	'digital-in-depth-interview': 'Digital In Depth Interview',
	'digital-purchase': 'Digital Purchase',
	'digital-registration': 'Digital Registration',
	'dollars-saved': 'Dollars Saved',
	'ethnograpic-observation': 'Ethnographic Observation',
	'focus-group': 'Focus Group',
	'free-shipping': 'Free Shipping',
	'free-trial': 'Free Trial',
	'gift-with-purchase': 'Gift with Purchase',
	'home-installation': 'Home Installation',
	'in-depth-interview': 'In Depth Interview',
	'meter-panel': 'Meter Panel',
	'mobile-optimized': 'Mobile Optimized',
	'offline-purchase': 'Offline Purchase',
	'percentage-saved': 'Percentage Saved',
	'product-coupon': 'Product Coupon',
	'product-sale': 'Product Sale',
	'shipping-offer': 'Shipping Offer',
	bogo: 'BOGO',
	category: 'Category',
	clearance: 'Clearance',
	coupon: 'Coupon',
	installation: 'Installation',
	local: 'Local',
	rebate: 'Rebate',
	sale: 'Sale',
};
