/**
 * Internal dependencies
 */
import { useCallback } from 'react';

/**
 * External dependencies
 */
import { setOffersListFilters, useDispatch, useSelector } from '@/store';
import { OfferFilters } from '@/offers';

const useOffersListFilters = () => {
	const dispatch = useDispatch();

	return [
		useSelector((state) => state.offersList.filters),
		useCallback(
			(filters: Partial<OfferFilters>) =>
				dispatch(setOffersListFilters(filters)),
			[dispatch]
		),
	] as const;
};

export default useOffersListFilters;
