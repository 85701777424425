/**
 * External dependencies
 */
import { useRef } from 'react';
import isEqual from 'lodash/isEqual';

const useHasChanged = <T extends any>(arg: T) => {
	const ref = useRef(arg);

	const hasChanged = !isEqual(arg, ref.current);

	ref.current = arg;

	return hasChanged;
};

export default useHasChanged;
