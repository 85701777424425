/**
 * External dependencies
 */
import { ConfigProvider } from 'antd';
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { getCssVar } from '@/utils';

type AntConfigProps = PropsWithChildren;

const AntConfig: FC<AntConfigProps> = ({ children }) => (
	<ConfigProvider
		theme={{
			components: {
				Layout: {
					headerBg: getCssVar('headerBg'),
					bodyBg: getCssVar('bodyBg'),
				},
			},
		}}
	>
		{children}
	</ConfigProvider>
);

export default AntConfig;
