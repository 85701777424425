/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { setOffersListPagination, useDispatch, useSelector } from '@/store';
import { PaginationData } from '@/types';

const useOffersListPagination = () => {
	const dispatch = useDispatch();

	return [
		useSelector((state) => state.offersList.pagination),
		useCallback(
			(pagination: Partial<PaginationData>) =>
				dispatch(setOffersListPagination(pagination)),
			[dispatch]
		),
	] as const;
};

export default useOffersListPagination;
