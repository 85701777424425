/**
 * Internal dependencies
 */
import { useGetSourcesQuery } from '@/api';
import useFilterOptions from '../useFilterOptions';

const useSourceFilterOptions = () =>
	useFilterOptions(useGetSourcesQuery().data);

export default useSourceFilterOptions;
