/**
 * External dependencies
 */
import { object, string } from 'yup';

/**
 * Internal dependencies
 */
import { getAppUrl } from '@/utils';

export const getTokenResponseSchema = object({
	accessToken: string().required(),
});

/**
 * Retrieves the authentication token.
 *
 * @return {string} The access token from the response data.
 */
export const getAuthToken = async () => {
	const response = await fetch(getAppUrl('auth/token'), {
		method: 'GET',
	});

	try {
		const data = await response.json();

		return (await getTokenResponseSchema.validate(data)).accessToken;
	} catch (error) {
		return null;
	}
};

/**
 * Forget the token.
 *
 * @return {Promise<void>} The result of the fetch operation.
 */
export const forgetAuthToken = () =>
	fetch(getAppUrl('auth/token'), {
		method: 'DELETE',
	});
