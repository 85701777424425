/**
 * External dependencies
 */
import { Divider, Input, Select, Typography } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { DefaultOptionType } from 'antd/es/select';
import { FC } from 'react';

/**
 * Internal dependencies.
 */
import ExitLinks from '@/offer-form/components/ExitLinks';
import OfferFormItem from '@/offer-form/components/OfferFormItem';

export type OfferDetailsLinksProps = {
	localizations: DefaultOptionType[];
	dispositions: DefaultOptionType[];
	onCopy: (name: NamePath) => void;
};

const Title = Typography.Title;

const OfferDetailsLinks: FC<OfferDetailsLinksProps> = ({
	localizations,
	dispositions,
	onCopy,
}) => (
	<>
		<OfferFormItem label="Entry link" name="entryLink" onCopy={onCopy}>
			<Input placeholder="https://example.com" />
		</OfferFormItem>
		<OfferFormItem
			label="Localization"
			name="localizations"
			onCopy={onCopy}
		>
			<Select options={localizations} mode="multiple" />
		</OfferFormItem>
		<Divider />
		<Title level={5}>Exit links</Title>
		<ExitLinks
			name="exitLinks"
			dispositions={dispositions}
			onCopy={onCopy}
		/>
	</>
);

export default OfferDetailsLinks;
