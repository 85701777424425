/**
 * Internal dependencies
 */
import { isAction } from '@/store/utils';
import { OfferFilters } from '@/offers';
import { Reducer } from '@/store/types';
import { setOffersListFilters } from '@/store/actions';
import initialState from '@/store/initialState';

const filters: Reducer<OfferFilters> = (
	state = initialState.offersList.filters,
	action
) =>
	isAction(action, setOffersListFilters)
		? {
				...state,
				...action.payload,
			}
		: state;

export default filters;
