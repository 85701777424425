/**
 * External dependencies
 */
import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/store/types';
import type { HttpMethod } from 'openapi-typescript-helpers';

/**
 * Internal dependencies
 */
import { APIError, BaseError } from '@/types';
import { Options, Paths, Response as ApiResponse } from './types';
import { removeEmpty } from '@/utils';
import getClient from './getClient';

type QueryArgs<M extends HttpMethod, P extends Paths<M>> = {
	method: M;
	path: P;
	args: Options<M, P>;
};

export const baseQuery: BaseQueryFn<
	any,
	unknown,
	BaseError | APIError,
	{},
	{ response: Response }
> = async <M extends HttpMethod, P extends Paths<M>>(
	{ method, path, args }: QueryArgs<M, P>,
	{ getState }: BaseQueryApi
) => {
	const token = (getState() as RootState).auth.token;

	if (!token) {
		return {
			error: { message: 'No access token found' },
		};
	}

	if (args?.params) {
		args = {
			...args,
			params: removeEmpty(args.params),
		};
	}

	try {
		const result = await (() => {
			const client = getClient(token);

			switch (method) {
				case 'delete':
					return client.DELETE(
						path as Paths<'delete'>,
						args as Options<'delete', Paths<'delete'>>
					) as Promise<ApiResponse<M, P>>;

				case 'head':
					return client.HEAD(
						path as Paths<'head'>,
						args as Options<'head', Paths<'head'>>
					) as Promise<ApiResponse<M, P>>;

				case 'get':
					return client.GET(
						path as Paths<'get'>,
						args as Options<'get', Paths<'get'>>
					) as Promise<ApiResponse<M, P>>;

				case 'options':
					return client.OPTIONS(
						path as Paths<'options'>,
						args as Options<'options', Paths<'options'>>
					) as Promise<ApiResponse<M, P>>;

				case 'patch':
					return client.PATCH(
						path as Paths<'patch'>,
						args as Options<'patch', Paths<'patch'>>
					) as Promise<ApiResponse<M, P>>;

				case 'post':
					return client.POST(
						path as Paths<'post'>,
						args as Options<'post', Paths<'post'>>
					) as Promise<ApiResponse<M, P>>;

				case 'put':
					return client.PUT(
						path as Paths<'put'>,
						args as Options<'put', Paths<'put'>>
					) as Promise<ApiResponse<M, P>>;

				case 'trace':
					return client.TRACE(
						path as Paths<'trace'>,
						args as Options<'trace', Paths<'trace'>>
					) as Promise<ApiResponse<M, P>>;
			}
		})();

		return result
			? {
					data: result.data,
					error: result.error
						? result.error?.detail
							? (result.error as APIError)
							: {
									message:
										result.error?.message ||
										'Unknown Error',
								}
						: undefined,
					meta: { response: result.response },
				}
			: { error: { message: 'Method not implemented' } };
	} catch (error) {
		return { error: { message: String(error) } };
	}
};
