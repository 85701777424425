/**
 * External dependencies
 */
import { createSelector } from '@reduxjs/toolkit';

/**
 * Internal dependencies
 */
import { decodeToken } from '@/auth/utils/token';
import { RootState } from '../types';

export const selectAuthTokenData = createSelector(
	(state: RootState) => state.auth.token,
	(token) => {
		if (!token) {
			return {
				token: null,
				expiresAt: null,
			};
		}

		const payload = decodeToken(token);

		return {
			token,
			expiresAt: (payload.exp ?? 0) * 1000,
		};
	}
);
