/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import useOffersListSelection from '@/state/hooks/useOffersListSelection';

type UseOffersSelectionContainer = ContainerFn<{
	addToSelection: (ids: string[]) => void;
	removeFromSelection: (ids: string[]) => void;
	resetSelection: () => void;
	select: (id: string) => void;
	selection: string[];
	setSelection: (selection: string[]) => void;
	toggle: (id: string) => void;
	unselect: (id: string) => void;
}>;

const useOffersSelectionContainer: UseOffersSelectionContainer = () => {
	const [selection, setSelection] = useOffersListSelection();

	const removeFromSelection = useCallback(
		(ids: string[]) =>
			ids.length &&
			setSelection(selection.filter((id) => !ids.includes(id))),
		[selection]
	);

	const select = useCallback(
		(id: string) =>
			!selection.includes(id) && setSelection([...selection, id]),
		[selection]
	);

	const unselect = useCallback(
		(id: string) => selection.includes(id) && removeFromSelection([id]),
		[selection, removeFromSelection]
	);

	return {
		addToSelection: useCallback(
			(ids: string[]) =>
				setSelection([
					...selection,
					...ids.filter((id) => !selection.includes(id)),
				]),
			[selection]
		),
		removeFromSelection,
		resetSelection: useCallback(() => setSelection([]), []),
		select,
		selection,
		setSelection,
		unselect,
		toggle: useCallback(
			(id: string) =>
				selection.includes(id) ? unselect(id) : select(id),
			[selection, select, unselect]
		),
	};
};

export default useOffersSelectionContainer;
