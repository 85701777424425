/**
 * External dependencies
 */
import { FieldData } from 'rc-field-form/lib/interface';

/**
 * Internal dependencies
 */
import { ContainerFn } from '@/types';
import { OfferFormValues } from '@/offer-form/types';
import { useCallback } from 'react';
import { useEditedOffer } from '@/state';
import { mapFormValuesToOffer, mapOfferToFormValues } from '@/offer-form/utils';
import { mapObjectToFormFields } from '@/utils';

type UseOfferFormContainer = ContainerFn<
	[FieldData[], (changedValues: any, values: OfferFormValues) => void]
>;

const useOfferFormContainer: UseOfferFormContainer = () => {
	const [offer, setOfferData] = useEditedOffer();

	return [
		offer ? mapObjectToFormFields(mapOfferToFormValues(offer)) : [],
		useCallback(
			(_, values) => setOfferData(mapFormValuesToOffer(values)),
			[setOfferData]
		),
	];
};

export default useOfferFormContainer;
