/**
 * External dependencies
 */
import { FC } from 'react';
import { Flex, Typography } from 'antd';

/**
 * Internal dependencies
 */
import { OfferStatus as OfferStatusType, OfferStatuses } from '@/offers';
import { Schedule } from '@/types';
import classes from './OfferFormTitle.module.scss';
import OfferSchedule from '../OfferSchedule';
import OfferStatus from '../OfferStatus';

const { Title } = Typography;

export type OfferFormTitleProps = {
	children?: string;
	onScheduleChange?: (dates: Schedule | undefined) => void;
	onStatusChange?: (status: OfferStatusType) => void;
	schedule?: Schedule | undefined;
	status?: OfferStatusType;
	statuses?: OfferStatuses;
};

const OfferFormTitle: FC<OfferFormTitleProps> = ({
	children = 'New survey',
	onScheduleChange,
	onStatusChange,
	schedule,
	status,
	statuses,
}) => (
	<Flex gap="0" align="center">
		<Title level={2} className={classes.title}>
			{children}
		</Title>
		<OfferStatus
			currentStatusId={status}
			onChange={onStatusChange}
			statuses={statuses}
		/>
		<OfferSchedule schedule={schedule} onChange={onScheduleChange} />
	</Flex>
);

export default OfferFormTitle;
