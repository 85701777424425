/**
 * Normalizes the given path by ensuring it starts with a forward slash.
 *
 * @param {string} path - The path to be normalized
 * @return {string} The normalized path
 */
export const normalizePath = (path: string) =>
	path.startsWith('/') ? path : `/${path}`;

/**
 * Normalizes an URL by removing trailing slash.
 *
 * @param {string} url - The input URL to be normalized
 * @return {string} The normalized URL
 */
export const normalizeUrl = (url?: string) => url?.replace(/\/$/, '');

/**
 * Retrieves the URL for the application, with an optional path appended.
 *
 * @param {string} path - an optional path to be appended to the application URL
 * @return {string} the complete application URL with an optional appended path
 */
export const getAppUrl = (path: string = '/') =>
	`${normalizeUrl(window.location.origin)}${path ? normalizePath(path) : ''}`;

/**
 * Adds search parameters to a URL.
 *
 * @param {string} url - The original URL.
 * @param {Record<string, any>} searchParams - The search parameters to be added to the URL.
 * @return {string} The updated URL with the added search parameters.
 */
export const addSearchParams = (
	url: string,
	searchParams: Record<string, any> = {}
) =>
	[
		url,
		new URLSearchParams(
			Object.fromEntries(
				Object.entries(searchParams).filter(([, value]) => !!value)
			)
		).toString(),
	]
		.filter((value) => !!value)
		.join('?');
