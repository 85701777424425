/**
 * External dependencies
 */
import { FileImageOutlined } from '@ant-design/icons';
import { Flex, Upload } from 'antd';
import { ComponentProps, FC, useEffect, useState } from 'react';

/**
 * Internal dependencies.
 */
import classes from './FileInput.module.scss';
import UploadButton from './UploadButton';

export type FileInputProps = {
	name: ComponentProps<typeof Upload>['name'];
	uploadUrl: string;
	label?: string;
	buttonLabel?: string;
	withDragDrop?: boolean;
	withPreview?: boolean;
	onUpload?: (data: unknown) => void;
};

const { Dragger } = Upload;

/**
 * @todo implement onUpload handler
 * @todo remove beforeUpload handler
 * @todo integrate with S3
 */
const FileInput: FC<FileInputProps> = ({
	name,
	uploadUrl,
	label,
	buttonLabel,
	withDragDrop,
	withPreview,
}) => {
	const [previewImageUrl, setPreviewImageUrl] = useState<string>();

	const beforeUpload = (file: File) => {
		setPreviewImageUrl(URL.createObjectURL(file));

		return false;
	};

	const uploadProps = {
		name,
		action: uploadUrl,
		method: 'post' as const,
		maxCount: 1,
		beforeUpload,
		onRemove: () => setPreviewImageUrl(''),
	};

	useEffect(
		() => () => {
			if (previewImageUrl) {
				URL.revokeObjectURL(previewImageUrl);
			}
		},
		[previewImageUrl]
	);

	return (
		<div className={classes.fileInput}>
			{label && (
				<div className="ant-form-item-label">
					<label>{label}</label>
				</div>
			)}
			<div className={classes.content}>
				{withDragDrop ? (
					<Dragger {...uploadProps}>
						<Flex gap="small" align="center">
							<UploadButton label={buttonLabel} />
							<span>or drag & drop files here</span>
						</Flex>
					</Dragger>
				) : (
					<Upload {...uploadProps}>
						<UploadButton label={buttonLabel} />
					</Upload>
				)}
				{withPreview && (
					<div className={classes.preview}>
						{previewImageUrl ? (
							<img src={previewImageUrl} alt="Preview" />
						) : (
							<FileImageOutlined />
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default FileInput;
