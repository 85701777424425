/**
 * External dependencies
 */
import { Button, Flex } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

/**
 * Internal dependencies
 */
import classes from './HeaderActions.module.scss';
import { title } from 'radash';

/**
 * Internal dependencies.
 */

export type HeaderActionConfig<T extends string> = {
	disabled?: boolean;
	icon: ReactNode;
	isPrimary?: boolean;
	label?: string;
	type: T;
};

export type HeaderActionsProps<T extends string> = {
	actions: HeaderActionConfig<T>[];
	disabled?: boolean;
	onAction?: (type: T) => void;
	onClose?: () => void;
};

const HeaderActions = <T extends string>({
	actions,
	disabled: globalDisabled,
	onAction,
	onClose,
}: HeaderActionsProps<T>): ReactNode => (
	<Flex gap="small">
		{actions.map(
			(
				{
					disabled: actionDisabled,
					icon,
					isPrimary = false,
					label,
					type,
				},
				key
			) => (
				<Button
					key={key}
					icon={icon}
					onClick={() => onAction?.(type)}
					type={isPrimary ? 'primary' : 'link'}
					disabled={globalDisabled || actionDisabled}
				>
					{label || title(type)}
				</Button>
			)
		)}
		{onClose && (
			<div className={classes.closeButtonWrapper}>
				<Button
					icon={<CloseOutlined />}
					onClick={onClose}
					className={classes.button}
				/>
			</div>
		)}
	</Flex>
);

export default HeaderActions;
