/**
 * External dependencies
 */
import { ReactNode } from 'react';

/**
 * Internal dependencies.
 */
import OffersHeaderFilters from '../OffersHeaderFilters';
import OffersHeaderStatuses from '../OffersHeaderStatuses';
import { Form, Typography } from 'antd';
import { OffersHeaderFiltersProps } from '../OffersHeaderFilters/OffersHeaderFilters';
import { mapObjectToFormFields } from '@/utils';

type Values<T extends string, S extends string> = Partial<Record<T, any>> & {
	status: S[];
};

type OffersHeaderProps<
	T extends string,
	S extends string,
> = OffersHeaderFiltersProps<T> & {
	onChange: (values: Values<T, S>) => void;
	statuses: Record<S, string>;
	values: Values<T, S>;
};

const OffersHeader = <T extends string, S extends string>({
	filters,
	onChange,
	statuses,
	values,
}: OffersHeaderProps<T, S>): ReactNode => (
	<Form<Values<T, S>>
		layout="vertical"
		onValuesChange={(_, newValues) => {
			filters.forEach(({ name, type }) => {
				const value = newValues[name] as any;
				if (type === 'date' && value instanceof Date) {
					newValues[name] = value.getTime() as any;
				}
			});

			onChange(newValues);
		}}
		fields={mapObjectToFormFields(values, (value) =>
			Array.isArray(value) && !value.length ? ['all'] : value
		)}
	>
		<Typography.Title level={1}>Catalog offers</Typography.Title>
		<OffersHeaderFilters filters={filters} />
		<OffersHeaderStatuses<S>
			options={statuses}
			values={values.status}
			onChange={(newStatuses) =>
				onChange({ ...values, status: newStatuses })
			}
		/>
	</Form>
);

export default OffersHeader;
